import { Injectable } from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';

@Injectable({
    providedIn: 'root'
})
export class PasswordUtilsService {
    public static validityRegex = /^[A-Za-z0-9`~!@#$%^*()_+\-={}|\[\]\\:";'<>?,.\/]*$/gm;
    public generatorCharacters = `0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz~!@-#$`;

    constructor() {}

    /**
     * Generates a secure, random password.
     * @param length total length of the password
     * @deprecated use createSavePasswordV2 instead
     */
    createSafePassword(length = 8): string {
        return new Array(length)
            .fill(undefined)
            .map(() => String.fromCharCode(Math.random() * 86 + 40))
            .join('');
    }

    /**
     * Generates a secure, random password based on a list of allowed characters.
     * @param length total length of the password
     * @param wishlist list of characters to be included in the password
     */
    createSafePasswordV2(length = 8, wishlist = this.generatorCharacters): string {
        return Array.from(crypto.getRandomValues(new Uint32Array(length)))
            .map(x => wishlist[x % wishlist.length])
            .join('');
    }

    public static passwordValidator(): ValidatorFn {
        return (control: FormControl<string>) => {
            const match = control?.value?.match(this.validityRegex);
            if (match) {
                return null;
            }
            return {
                invalidPasswordCharacters: {
                    message: 'Password includes invalid characters.',
                    validCharacters:
                        'Valid password characters include letters, number and the following special characters (`~!@#$%^*()_+-={}|[]:";\'<>?,./)'
                }
            };
        };
    }
}
