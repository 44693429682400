import { PropsFilterFnFactory } from '@ngrx/data';
import { ReadRole } from '../authorization/generated';

export function sortByName(x: ReadRole, y: ReadRole) {
    return x.name.localeCompare(y.name);
}

export function roleFilter(entities: ReadRole[], pattern: string) {
    return PropsFilterFnFactory<ReadRole>(['name'])(entities, pattern);
}
